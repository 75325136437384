import React, { createContext, useMemo, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useQuery, UseQueryResult } from 'react-query'
import { Client } from '@hooks/useWidgetClientColumns'
import useWidgetColumns from '@hooks/useWidgetColumns'
import $Widget from '@services/Widget'
import useTable from '@hooks/useTable'
import Clients from './Clients/Clients'
import Builder from './Builder/Builder'
import IWidget from '@interfaces/IWidget'
import Dashboard from './Dashboard/Dashboard'

export type Props = {
  clients: Client[]
  builder: { client: Client | null } | null
  setBuilder: React.Dispatch<React.SetStateAction<Props['builder']>>
  dashboard: { client: Client | null } | null
  setDashboard: React.Dispatch<React.SetStateAction<Props['dashboard']>>
  $widgets: UseQueryResult<IWidget[], unknown>
}

export const WidgetsContext = createContext<Props>({} as Props)

const Widgets: React.FC = () => {
  const [ builder, setBuilder ] = useState<Props['builder']>(null)
  const [ dashboard, setDashboard ] = useState<Props['dashboard']>(null)

  const $widgets = useQuery('widgets', {
    queryFn: $Widget.all,
  })

  const columns = useWidgetColumns()

  const data = useMemo(() => $widgets.data || [], [$widgets.data])

  const { Table, Pagination } = useTable(data, {
    columns,
    isLoading: $widgets.isLoading,
  })

  const clients = useMemo(() => {
    if (!$widgets.data)
      return []

    return Object.values($widgets.data.reduce((clients, widget) => {
      widget.clients.forEach(client => {
        if (!clients[client.id]) {
          clients[client.id] = {
            ...client,
            widgets: [],
          }
        }

        const { id, name } = widget

        clients[client.id].widgets.push({
          id,
          name,
          query: client.query,
          role: client.role,
        })
      })

      return clients
    }, {} as Record<number, Client>))
  }, [$widgets.data])

  return (
    <WidgetsContext.Provider
      value={{
        clients,
        builder,
        setBuilder,
        dashboard,
        setDashboard,
        $widgets,
      }}
    >
      <Builder />

      <Dashboard />

      <div className="row align-items-center justify-content-between">
        <div className="col-12 col-md-4 mb-3">
          <h1 className="mb-0">Widgets</h1>

          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/">Início</Link>
            </li>

            <li className="breadcrumb-item active" aria-current="page">Widgets</li>
          </ol>
        </div>

        <div className="col-12 col-md-4 d-flex justify-content-end">
          <button className="btn btn-primary pe-3 ps-3" onClick={() => setBuilder({ client: null })}>
            Adicionar cliente
          </button>

          <button className="btn btn-primary pe-3 ps-3 ms-2" onClick={() => setDashboard({ client: null })}>
            Editar dashboard
          </button>
        </div>
      </div>

      <Tabs defaultActiveKey="widgets">
        <Tab title={`Widgets (${$widgets.data?.length ?? 0})`} eventKey="widgets">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive">
                <Table />
              </div>
            </div>

            <Pagination>
              {pagination => (
                <div className="card-footer d-flex justify-content-end">
                  {pagination}
                </div>
              )}
            </Pagination>
          </div>
        </Tab>

        <Tab title="Clientes" eventKey="clients">
          <Clients $widgets={$widgets} />
        </Tab>
      </Tabs>
    </WidgetsContext.Provider>
  )
}

export default Widgets
