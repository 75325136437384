import { QueryFunction } from 'react-query'
import axios from 'axios'
import IWidget from '@interfaces/IWidget'

export const all = (): Promise<any> => {
  return axios.get('/clients/')
}

export const find = (clientId: number): Promise<any> => {
  return axios.get(`clients/${clientId}`)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const update = (client_id: number, client: any): Promise<any> => {
  return axios.put('clients/' + client_id, client)
}

const widgets: QueryFunction<Array<IWidget>, [string, number]> = ({ queryKey: [, id] }) => {
  return axios.get(`/widgets/clients/${id}`).then(({ data }) => data)
}

const $Client = {
  all,
  find,
  update,
  widgets,
}

export default $Client
