import React, { useContext } from 'react'
import { ReactComponent as Collapse } from '../../resources/svg/collapse.svg'
import PanelContext from '../../contexts/Panel'
import MenuItem from './MenuItem/MenuItem'

import { Aside, CollapseButton, SidebarContainer } from './Sidebar.styles'
import AuthContext from '@contexts/Auth'

import AccessLevelPermissionEnum from '@enums/AccessLevelPermission.enum'

const Sidebar: React.FC = () => {
  const { admin } = useContext(AuthContext)
  const { sidebarCollapsed, setSidebarCollapsed } = useContext(PanelContext)

  return (
    <SidebarContainer className={'sidebar col-12 ' + (sidebarCollapsed ? 'col-md-1 collapsed' : 'col-md-2')}>
      <Aside className="sidebar-sticky">
        <CollapseButton className="btn-collapse d-none d-md-block" onClick={() => setSidebarCollapsed(!sidebarCollapsed)}>
          <Collapse />
        </CollapseButton>

        <MenuItem title="Dashboard" icon="panel" path="/" />
        <MenuItem
          title="Usuários"
          icon="userAdmin"
          path={[
            '/usuarios',
            '/alunos',
            '/professores',
            '/corretores',
            '/gestores',
            '/multiusuarios',
          ]}
        >
          {(admin.permissions.includes(AccessLevelPermissionEnum.VIEW_ADMINS) || admin.isSuperAdmin) && (
            <MenuItem title="Usuários" path="/usuarios" />
          )}
          {(admin.permissions.includes(AccessLevelPermissionEnum.VIEW_STUDENTS) || admin.isSuperAdmin) && (
            <MenuItem title="Alunos" path="/alunos" />
          )}
          {(admin.permissions.includes(AccessLevelPermissionEnum.VIEW_TEACHERS) || admin.isSuperAdmin) && (
            <MenuItem title="Professores" path="/professores" />
          )}
          {(admin.permissions.includes(AccessLevelPermissionEnum.VIEW_REVISORS) || admin.isSuperAdmin) && (
            <MenuItem title="Corretores" path="/corretores" />
          )}
          {(admin.permissions.includes(AccessLevelPermissionEnum.VIEW_MANAGERS) || admin.isSuperAdmin) && (
            <>
              <MenuItem title="Gestores" path="/gestores" />
              <MenuItem title="Multiusuários" path="/multiusuarios" />
            </>
          )}
        </MenuItem>

        <MenuItem title="Turmas" icon="classroom" path="/turmas" />

        <MenuItem title="Associação em massa" icon="inscription" path="/associacao-em-massa" />
        <MenuItem title="Turmas em massa" icon="inscription" path="/turmas-em-massa" />
        <MenuItem title="E-mail Gestores" icon="inscription" path="/email-gestores" />
        <MenuItem title="Gerar Boletim" icon="studentGrades" path="/gerar-boletim" />

        {admin.permissions.includes(AccessLevelPermissionEnum.VIEW_PERMISSIONS) && (
          <>
            <MenuItem title="Permissões" icon="toManage" path="/permissoes" />
            <MenuItem title="Chaves de APP" icon="toManage" path="/chaves-de-app" />
          </>
        )}

        {(admin.permissions.includes(AccessLevelPermissionEnum.VIEW_REPORTMENTS) || admin.isSuperAdmin) && (
          <MenuItem
            title="Relatórios"
            icon="product"
            path={[
              '/relatorios',
              '/relatorios/categorias',
              '/relatorios/autorizacoes',
              '/relatorios/filtros',
            ]}
          >
            <MenuItem title="Relatórios" path="/relatorios" />
            <MenuItem title="Categorias" path="/relatorios/categorias" />
            <MenuItem title="Autorizações" path="/relatorios/autorizacoes" />
            <MenuItem title="Filtros" path="/relatorios/filtros" />
          </MenuItem>
        )}

        {admin.permissions.includes(AccessLevelPermissionEnum.VIEW_ACCESSLEVEL) && (
          <MenuItem title="Níveis de acesso" icon="user" path="/niveis-de-acesso" />
        )}

        {admin.permissions.includes(AccessLevelPermissionEnum.VIEW_ADMINS) && (
          <MenuItem title="Administradores" icon="user" path="/administradores" />
        )}

        <MenuItem
          title="Questões"
          icon="evaluationsAndResolutions"
          path={[
            '/tipos-de-categoria-de-questao',
            '/categorias-de-questao',
            '/questoes-de-categorias-de-questao',
          ]}
        >
          <MenuItem title="Tipos de Categoria de Questão" path="/tipos-de-categoria-de-questao" />
          <MenuItem title="Categorias de Questão" path="/categorias-de-questao" />
          <MenuItem title="Questões de Cat. de Questão" path="/questoes-de-categorias-de-questao" />
        </MenuItem>

        <MenuItem title="Templates de leitura" icon="user" path="/templates-de-leitura" />
        <MenuItem title="Agendamentos de Jobs" icon="engagement" path="/agendamentos" />
        <MenuItem title="Ferramentas" icon="engagement" path="/ferramentas" />
        <MenuItem title="Envelopes" icon="evaluationsAndResolutions" path="/envelopes" />
        <MenuItem title="Relatório de auditorias e erros" icon="product" path="/relatorio-auditoria-erros" />
        <MenuItem title="Relatório de Resp. Inscrição" icon="product" path="/relatorio-responsavel-inscricao" />
        <MenuItem title="Importar em massa" icon="inscription" path="/importar-usuarios" />
        <MenuItem
          title="Escolas"
          icon="evaluationsAndResolutions"
          path={[
            '/associacao-escola-simulado',
            '/associacao-escola-plataforma',
          ]}
        >
          <MenuItem title="Produtos" path="/associacao-escola-simulado" />
          <MenuItem title="Plataformas" path="/associacao-escola-plataforma" />
        </MenuItem>

        <MenuItem title="Planos de Estudo" icon="product" path="/plano-estudo" />
        <MenuItem title="Divulgação de Gabarito" icon="studentGrades" path="/divulgacao-gabarito" />

        <MenuItem title="Widgets" icon="templates" path="/widgets" />
      </Aside>
    </SidebarContainer>
  )
}

export default Sidebar
